export const COUNT_JUMBO = 'COUNT_JUMBO'
export const HAS_BACK_IMAGE = 'HAS_BACK_IMAGE'
export const HIDE_DOWN = 'HIDE_DOWN'
export const SET_BACK_SLIDER = 'SET_BACK_SLIDER'
export const SET_IMAGE = 'SET_IMAGE'
export const SET_MEDIA = 'SET_MEDIA'
export const SET_RIGHT_IMAGE = 'SET_RIGHT_IMAGE'
export const SET_SIC_GRIDE = 'SET_SIC_GRIDE'
export const SET_WORK_FILTER = 'SET_WORK_FILTER'
export const SLIDER_COUNT = 'SLIDER_COUNT'
export const THEME = 'THEME'
export const THIS_FOOTER = 'THIS_FOOTER'
export const TOGGLE_CONTACT = 'TOGGLE_CONTACT'
export const TOGGLE_GRID = 'TOGGLE_GRID'
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const TOGGLE_WORK_FILTER = 'TOGGLE_WORK_FILTER'
export const UPDATE_CONTACT_MESSAGES = 'UPDATE_CONTACT_MESSAGES'
export const UPDATE_CURRENT = 'UPDATE_CURRENT'
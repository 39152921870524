/* global tw */
import React from 'react'
import { css } from 'react-emotion'
import { withStateHandlers } from 'recompose'

import { ifElse, isNil } from '../../helpers'

export const Img = withStateHandlers(
  ({ initialColor = 'rgba(0, 0, 0, 0.1)' }) => ({
    backgroundColor: initialColor,
  }),
  {
    onLoad: () => () => ({
      backgroundColor: 'rgba(0, 0, 0, 0)',
    }),
  }
)(
  ({
    backgroundColor,
    onLoad,
    src,
    className,
    style,
    width = 720,
    ...props
  }) => (
    <>
      {ifElse(
        isNil,
        () => (
          <div
            className={css`
              ${tw(['w-full'])};
            `}
            style={{ backgroundColor }}
          />
        ),
        () => (
          <>
            <img
              className={css`
                ${tw(['absolute', 'pin', 'w-full', 'h-full'])}
                object-fit: cover;
                z-index: -1;
              `}
              src={`${parseImageUrl(src.url)}?&fit=clamp&w=32`}
              style={{ backgroundColor, ...style }}
              alt=""
            />
            <img
              className={css`
                ${tw(['w-full', 'h-full'])}
                object-fit: cover;
              `}
              src={`${parseImageUrl(src.url)}?&fit=clamp&lossless=true&w=${width}`}
              {...{ onLoad }}
              style={style}
              alt=""
              loading="lazy"
              {...props}
            />
          </>
        )
      )(src && src.url)}
    </>
  )
)

function parseImageUrl(url) {
  const regex = /(^.+)\?.+$/g
  const res = regex.exec(url)

  return (res && res[1]) || url
}
